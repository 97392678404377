@import '../../styles/helpers/index.scss';

.offers {
  .container {
    &__inner {
      min-height: 300px;
      border-top: 1px solid map-get($colors, 'red');
      display: flex;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
        padding: 0 0 30px;
      }

      & > div {
        max-width: 50%;
        width: 100%;

        @media screen and (max-width: $tablet-sm) {
          max-width: 100%;
        }
      }
    }
  }

  &__left {
    position: relative;
    padding: 0 0 0 60px;
    color: map-get($colors, 'red');

    @include align-center;
    justify-content: center;

    border-right: 1px solid map-get($colors, 'red');

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 0;
      text-align: center;
      border-right: 0;
    }

    p:first-of-type {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 100%;
      background-color: map-get($colors, 'red');
      color: #fff;

      text-align: center;
      font-weight: 600;
      font-size: 42px;
      line-height: 60px;
      writing-mode: vertical-rl;
      transform: rotate(180deg);

      @media screen and (max-width: $tablet-sm) {
        writing-mode: horizontal-tb;
        transform: none;
        width: 100%;
        height: auto;
      }
    }

    p:last-of-type {
      font-weight: 600;
      font-size: 42px;
      line-height: 150%;

      @media screen and (max-width: $tablet-sm) {
        padding: 90px 0 0;
        font-size: 32px;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet-sm) {
      margin: 15px 0 0;
      display: none;
    }

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      color: map-get($colors, 'text');

      // @media screen and (max-width: $tablet-sm) {
      //   text-align: center;
      //   font-size: 20px;

      //   @include align-center;
      //   justify-content: center;
      // }

      &:not(:last-of-type) {
        margin: 0 0 35px;

        // @media screen and (max-width: $tablet-sm) {
        //   margin: 0 0 15px;
        // }
      }

      span {
        color: map-get($colors, 'red');
        font-weight: 600;
        font-size: 36px;

        // @media screen and (max-width: $tablet-sm) {
        //   padding: 0 10px 0 0;
        // }

        &:first-of-type {
          font-size: 80px;
          line-height: 130%;
          padding: 0 15px 0 0;

          // @media screen and (max-width: $tablet-sm) {
          //   font-size: 32px;
          //   // padding: 0 10px 0 0;
          // }
        }
      }
    }
  }

  &__mobile {
    display: none;
    margin: 15px 0 0;
    padding: 0 15px 0;
    text-align: center;

    @media screen and (max-width: $tablet-sm) {
      display: block;
    }

    p {
      font-size: 20px;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }

      span {
        padding: 0 5px 0 0;
        color: map-get($colors, 'red');
        font-weight: 600;
        font-size: 24px;
      }
    }
  }
}
