@import '../../styles/helpers/index.scss';

.home {
  height: 100vh;

  &__inner {
    height: inherit;

    @include align-center;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 45px 0 30px;
      font-size: 36px;
      text-align: center;
      text-transform: uppercase;
    }

    ul {
      li {
        position: relative;
        text-indent: 20px;

        &::before {
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 0;

          transform: translateY(-50%);

          background-color: map-get($colors, 'red');
          border-radius: 50%;
        }

        a {
          text-decoration: none;
          font-size: 22px;
          color: map-get($colors, 'text');

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
