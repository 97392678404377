@import '../../styles/helpers/index.scss';

.book-online {
  .container {
    &__inner {
      border-top: 1px solid map-get($colors, 'red');
      position: relative;
      padding: 75px 0 60px 100px;

      @media screen and (max-width: $tablet-sm) {
        padding: 0 0 75px;
      }
    }
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    background-color: map-get($colors, 'red');
    color: #fff;

    text-align: center;
    font-weight: 600;
    font-size: 42px;
    line-height: 60px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    @media screen and (max-width: $tablet-sm) {
      writing-mode: horizontal-tb;
      position: relative;

      width: 100%;
      transform: none;
    }
  }

  .section-title {
    @media screen and (max-width: $tablet-sm) {
      text-align: center;
      padding: 15px 15px 0;
    }
  }

  form {
    margin: 25px 0 0;
    @include grid($row-gap: 20px);

    @media screen and (max-width: $tablet-sm) {
      padding: 0 15px;
    }

    *::placeholder {
      font-family: 'Poppins', sans-serif;
      color: map-get($colors, 'text');
    }

    input,
    select {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: none;
      border-bottom: 2px solid map-get($colors, 'red');
      outline: none;
      color: map-get($colors, 'text');
      font-family: 'Poppins', sans-serif;
    }

    input[type='email'] {
      margin: 0 0 20px;
    }

    input[type='submit'] {
      line-height: unset;
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
    }

    textarea {
      width: 100%;
      height: 100px;
      border: none;
      border-bottom: 2px solid map-get($colors, 'red');
      outline: none;
      font-family: 'Poppins', sans-serif;
    }
  }
}
